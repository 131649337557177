import React from 'react';
import './Footer.css';
import whatsappImage from '../assets/images/whatsapp.png'
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>ABOUT US</h3>
          <ul>
            <li><a href="/it-staffing">IT Staffing</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/careers">Careers</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>SERVICES</h3>
          <ul>
            <li><a href="/Website Design">Website Design</a></li>
            <li><a href="/Software Development">Software Development</a></li>
            <li><a href="/App Development">App Development</a></li>
            <li><a href="/UI/UX Design">UI/UX Design</a></li>
            <li><a href="/Freelancer">Freelancer</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>CONTACT</h3>
          <p>Email: <a href="Teams@Soft-LI.com"> Teams@Soft-LI.com  </a></p>
          <p>Phone: <a href="tel:+91 931-093-3579">+91 931-093-3579</a></p>
          <p>Address:  <a href=" New Delhi, INDIA ">New Delhi,  INDIA</a></p>
          <h3>FOLLOW US ON</h3>
          <div className="social-icons-footer">
            <a href="https://facebook.com" target='blank'><i className="fab fa-facebook-f"></i></a>
            <a href="https://linkedin.com" target='blank'><i className="fab fa-linkedin-in"></i></a>
            <a href="https://instagram.com" target='blank'><i className="fab fa-instagram"></i></a>
            <a href="https://twitter.com" target='blank'><i className="fab fa-twitter"></i></a>
          </div>
        </div>

        {/* WhatsApp Chat Support Button */}
        <div className="whatsapp-chat">
          <a
            href="https://wa.me/919310933579?text=Hello%20Soft-LI%20Global%20Technologies,%20I%20need%20help."
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-button"
          >
            <img src={whatsappImage} alt="WhatsApp Icon" />
            <span className="whatsapp-tooltip">Need Help? Chat with us</span>
          </a>
        </div>
      </div>
      

      <div className="footer-bottom">
        <p>© 2024 Soft-LI Global Technologies. All rights reserved.</p>
        <p>Designed & Developed by <a href="https://www.soft-li.com/" target="_blank" rel="noopener noreferrer">Soft-LI Global Technologies</a></p>
      </div>
    </footer>
  );
}

export default Footer;
